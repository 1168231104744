import styled from 'styled-components';

const Link = styled.a`
    text-decoration: none;
    color: #000;
    text-align: center;
    &:hover {
        text-decoration: underline;
    }
`;

export default Link;